import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Card, CardBody } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import 'swiper/css/bundle';
import '../styles/swiper.scss';

const CrewCarousel = () => {

    const quotesData = [

{        
image: "/img/kudos/munquera.jpg",
name: "Michael Unquera",
title: "Director of Cyber Security and Privacy",
quote: <>Working closely with Tonya, I witnessed her significant impact on our daily operations, especially her efforts in championing product improvements. Her commitment to her team and their contributions to the organization made a great and positive impact on the company.<br/><br/>Tonya is not only technically skilled but also exceptionally organized and a strong leader. <span>What sets Tonya apart is her proactive approach</span> to process and product improvement. She always advocated for her team, ensuring their voices were heard and contributions valued. Tonya's influence on the company was profound, leaving a lasting impression on both the company's culture and myself.</>,
},
{
image: "/img/kudos/mdonnelly.jpg",
name: "Mark Donnelly",
title: "VP, Global Engineering",
quote: <>Tonya's approach to leadership is deeply ingrained with empathy for her team, allowing her to foster a supportive and collaborative work environment. Her ability to manage complex projects, while navigating company dynamics with finesse, is truly commendable. Tonya's dedication to maintaining a customer-focused perspective, coupled with her rich artistic background in UX/UI design and meticulous attention to detail, enables her to lead with innovation and precision.<br/><br/>Her unique skill set combined with her commitment to excellence, makes her <span>an invaluable asset to any organization</span> seeking a leader who can balance team morale with achieving ambitious goals.</>
},
{
image: "/img/kudos/eharrington.jpg",
name: "Eric Harrington",
title: "Co-Founder & COO",
quote: <>Tonya joined us as our lead UI/UX engineer and quickly demonstrated a <span>keen eye for design and an unwavering attention to detail</span>. Her strategic approach and commitment to quality played a pivotal role in the continuous improvement of our products. She was not only a reliable team player but also a leader who prioritized effective communication.<br/><br/>Tonya was a valuable asset to our team, consistently delivering results and positively impacting both our products and the work culture. I highly recommend her for any role that demands technical expertise, leadership skills, and a customer-centric approach. Her dedication and proficiency make her a standout professional.</>
},
{
image: "/img/kudos/gjackson.jpg",
name: "Gregory Jackson",
title: "Director of Engineering",
quote: <>Tonya is an exceptional Team Leader.<br/><br/>Her ability to multitask while ensuring all details are covered is unparalleled. Her leadership is evident by her ability to streamline detailed processes and ensure that everyone is on the same page with thorough documentation. By her determined efforts, we have increased our team's organization and cross-functional coordination.<br/><br/>Additionally, Tonya is the <span>strongest UX/UI developer I've had the pleasure of working with</span>. Her technical expertise paired with leadership ability is a strong combination. Any organization would be lucky to have Tonya leading one of their technical teams.</>
},
{
image: "/img/kudos/ajones.jpg",
name: "Amanda Jones",
title: "Customer Success Manager",
quote: <><span>Tonya is an absolute superstar.</span> I was lucky enough to work almost 5 years with her and see her shine not only in her role as a UI/UX Engineer, but as a leader guiding and managing many others. On top of constantly stepping up to fill the spaces needed as change occurred, she took on additional roles to influence and maintain a positive environment and culture.<br/><br/>As a CSM I often partnered with her to affect change for our customers and products, and through her guidance we were able to bridge the gaps between customers' needs and the value we could bring to their own companies. Her positive attitude, creativity, and knowledge are vital in making what we all do a success.</>
},
{
image: "/img/kudos/mkermani.jpg",
name: "Morag Kermani",
title: "Sr. Software Engineer",
quote: <>Tonya has two character traits that make her exceptional at everything she does.<br/><br/>1. High standards: Tonya's standards and her attention to detail are something that we all aspire to, but for Tonya they're not an aspiration. <span>You may wind up adjusting your perception of what "thorough" means after you've worked with Tonya.</span><br/><br/>2. Creative/Resourceful: Obstacles are inevitable on the path to the project completion. Tonya finds creative ways around them and employs outside the box solutions, which makes her flexible without compromising her high standards. She's an incredibly talented designer and keeps her UI/UX skills sharp.</>
},
{
image: "/img/kudos/tpitts.jpg",
name: "Travis Pitts",
title: "Senior Software Engineer",
quote: <>There are few people I have come across in my career that put the level of effort and passion into work that Tonya does. As a UI/UX SME she immediately started producing deliverables that were so good that they routinely oversold the product for which she built them. She's the type of person that you can hand a project off to and she will just handle it. Even if she doesn't have the immediate skill set to address something on the spot, she will swiftly build the tools and gain the understanding to make it happen.<br/><br/>Tonya is the easiest recommendation I could ever make to anyone looking for <span>elite leadership/people skills combined with an understanding of UI/UX that is masterful</span>.</>
},
{
image: "/img/kudos/nfinegan.jpg",
name: "Niki Finegan",
title: "Demand Generation Manager",
quote: <>I couldn't be more excited to give a massive thumbs up to Tonya, the creative force and all-around awesome human I had the pleasure of working with!<br/><br/>Tonya is not your average Senior UX/UI designer – she's a design wizard! Her ability to turn ideas into designs is next level. She makes digital art look easy.<br/><br/>Tonya doesn't just excel at design, she goes the extra mile to engage with her team ensuring everyone feels connected.<br/><br/><span>Tonya isn't just a design guru; she's the heart of her team.</span> Her positivity and genuine care for her work makes her not just a colleague but a friend.</>
},
{
image: "/img/kudos/pambler.jpg",
name: "Paul Ambler",
title: "Principal Software Engineer",
quote: <><span>Tonya was a great engineering manager for our team!</span> She walked a fine line of fighting for her team of developers, while effectively finding compromises with the executive/leadership team. She advocated daily for our well-being and fighting for what developers thought was best for the product/customers. This meant pushing back on product and support teams amid competing priorities. Even if we didn't get what we wanted, we knew that Tonya had our back and tried to her best to figure out what could be done.<br/><br/>I was also able to work with Tonya on front end/UX designs for development. She always had great feedback and brought valuable insights.</>
},
{
image: "/img/kudos/rconrades.jpg",
name: "Rick Conrades",
title: "Engineering Manager",
quote: <>I have had the pleasure of working with Tonya at two different firms. In both, <span>her mastery of her position was evident</span>.<br/><br/>Tonya is gifted in graphic art and user experience, and knows how to apply various technologies to deliver. Her work is detailed, thorough, and accurate. But her gifts don't end there, as she is both left- and right-brained, and brings strong leadership and management skills to the table. Her teams are efficient, and she has shown on numerous occasions that she can streamline processes and develop strong agile skills.<br/><br/>I am proud to have worked with her, and hope the opportunity comes again!</>
},
{
image: "/img/kudos/btallis.jpg",
name: "Barry Tallis",
title: "Director of Product",
quote: <>I have had the pleasure of working with Tonya while at TeamSupport.<br/><br/>She was instrumental in defining the new look and feel of our new product line. Tonya created the new design system and complete documentation on how to utilize the system for our front-end developers.<br/><br/>She has the ability to manage multiple projects at the same time and <span>her requirements documentation is the most detailed and accurate that I have seen</span> in my 25+ years in Product.</>
},
{
image: "/img/kudos/rheath.jpg",
name: "Roy Heath",
title: "Web Developer",
quote: <>Tonya helped us with design, graphics, and front end programming support. <span>I found her layouts to be remarkably easy to work with</span> because she has a background in development and the layouts were set up as a developer would have done them. This not only saved a considerable amount of time but also resulted in a cleaner and more stable implementation.<br/><br/>Tonya is also a great person to work with in general. She is very creative and has a subtle sense of humor that is appreciated when deadlines are tight.</>
},
{
image: "/img/kudos/dpost.jpg",
name: "Dave Post",
title: "UX/UI Designer & Team Supervisor",
quote: <>Tonya has an excellent work ethic and a wide breadth of web development knowledge and experience. She has great analytical skills and you can count on her to get the job done no matter how difficult.<br/><br/>Tonya is a very skilled project manager so you always know how long a task is going to take and exactly where she is in the process timeline. She also annotates her work like no-one I've ever worked with. Her in-code comments are thorough and exact but <span>her signature color-coded multi-tab spreadsheets are a joy to behold</span>.</>
},
];
    



    return (
        <div className="position-relative">
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    900: {
                        slidesPerView: 2
                    },
                    1400: {
                        slidesPerView: 3
                    }
                }}
                breakpointsBase="window"
                autoplay={{ delay: 18000 }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
            >
                {quotesData.map((item, index) => (
                    <SwiperSlide key={index} className="kudo">
                        <Image className="qimage" src={item.image} alt="" roundedCircle />
                        <div className="qname">{item.name}</div>
                        <div className="qtitle">{item.title}</div>
                        <Card>
                            <CardBody>
                                <i className="bi bi-quote lquo"></i>
                                <div className="quote">{item.quote}</div>                                
                                <i className="bi bi-quote mirror-icon rquo"></i>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default CrewCarousel;