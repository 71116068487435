import React from 'react';
import { ParallaxProvider } from "react-scroll-parallax";
import { StudiesBanner } from "../components/StudiesBanner.tsx";
import StudiesBlurb from '../components/StudiesBlurb.jsx';
import { Container } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardBody } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { Slide } from "react-awesome-reveal";
import ContactIcons from '../components/ContactIcons';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../styles/studies.scss";
import "../styles/hero-studies.scss";

const CaseStudies = () => {
  return (
    <>
      <div id="casestudies">&nbsp;</div>
      <div className='mt-5'>
        <ParallaxProvider>
          <StudiesBanner />
        </ParallaxProvider>
      </div>
      <div className="studies-stack">
        <Container fluid="xl">
          <StudiesBlurb/>
        </Container>
      </div>


      <div className="studies-intro">
        <Container fluid="xl">
          <Row>
            <Col md={7} lg={9} className="pe-0 pe-lg-3">

              <h1 className="mb-4">Case Studies</h1>

              <Row>
                <Col lg={6} className="mb-4">                  

                  <h2>UI/UX</h2>

                  <h3 className="h5"><a href="#verizon">Verizon's Internal Applications</a></h3>
                  <p>Saving Millions</p>

                  <h3 className="h5"><a href="#success">TeamSuccess</a></h3>
                  <p>Expanding Product Offerings</p>

                  <h3 className="h5"><a href="#support">TeamSupport Modernization</a></h3>
                  <p>Performance Enhancing</p>

                  <h3 className="h5"><a href="#tickets">TeamSupport Tickets</a></h3>
                  <p>Boosting User Experience</p>
                </Col>
                <Col lg={6}>
                  <h2>Leadership</h2>

                  <h3 className="h5"><a href="#engineering">Engineering Management</a></h3>
                  <p>Elevating Quality and Quantity</p>

                  <h3 className="h5"><a href="#engagement">Employee Engagement</a></h3>
                  <p>Fostering Interaction</p>

                  <h2>Bonus</h2>

                  <h3 className="h5"><a href="#origin">An Origin Story</a></h3>
                  <p>Strategic Marketing</p>

                </Col>
              </Row>

            </Col>
            <Col md={5} lg={3} className="align-self-center mb-3">
              <Slide direction="right">
                <Card className="team-content-area text-center mx-2 mx-md-auto">
                  <CardBody>
                      <div className="member-thumb my-4">
                          <Image src="img/avatars/avatar5.png" alt="" roundedCircle fluid className="transition-500" />
                      </div>
                      <h3 className="h5">Tonya Abna</h3>
                      <small className="text-uppercase letter-spacing d-block">Girl Boss</small>
                      <small className="smaller text-uppercase letter-spacing d-block">Master of hustlin'</small>
                      <div className="member-social-info my-4">
                        <ContactIcons />
                      </div>
                    </CardBody>
                </Card>
              </Slide>
            </Col>
          </Row>
          </Container>
      </div>

      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">
            <span><i className="bi bi-quote lquo"></i>Tonya went above and beyond in recommending solutions that will be able to take us into <span className="text-nowrap">the future.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Shainy Tress</div>
              <small>Senior Manager &ndash; Learning Development</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="verizon" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}>
              <h3>Verizon's Internal Applications</h3>
              <p className="lead">Used by 160,000 employees • $11.2 Million in cost savings</p>
              <h4>Delivering Smart, Connected Experiences</h4>
              <p>The employee experience is at the heart of Verizon's internal applications. Verizon is home to 160,000 employees of vast diversity in terms of their jobs, demographics and personal needs. The goal of the projects I worked on as part of Verizon's Global Technology Creative Services Team was to deliver the kind of high-end, innovative solutions to those users that they would expect from best-in-class external facing solutions.</p>          
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-verizon-1.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>Driving Empathy and UX with Personas</h4>
              <p>It was important through the design process to be aware of the ranges and types of potential users for the global internal applications in order to ensure that all employees were being served in an equal and engaging manner. This was driven through user research and personas. It was not just a matter of understanding the high level roles, goals, and actions of those users. We also took into consideration cultural differences. One good example was making sure that the employee profiles could accommodate any number of words for employee names or any number of characters in any part of the name, such as 'Venkata Satya Ramakrishna Vishnubhottla Kottumuklalramrai'. In the older system, some users had expressed offense at having their names cut short in particular layouts.</p>          
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-verizon-2.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>Maintaining Brand Consistency</h4>
              <p>While creating user-driven designs, it was crucial to also adhere to Verizon's established branding, using and contributing to their Design System and Pattern Library. This provided a robust front end framework allowing developers across Verizon to build a consistent, easy to use, and accessible responsive site in minutes. One challenge to this, which drove the need to extend the platform, was that the original branding guidelines were created for print and marketing materials, which don't always translate directly to the needs of web applications. One example of this was the use of red and black for the primary and secondary colors for Verizon, neither of which is typically an acceptable choice for text hyperlinks, as opposed to call-to-action buttons. Red could falsely indicate some sort of error or urgency, while black could be read as static text. I worked with the Design Systems team to resolve this and other UI/UX issues.</p>          
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-verizon-3.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>Informing and Empowering Employees</h4>
              <p>Major drivers for Verizon's digital workplace strategy were cost savings and cost avoidance, KPIs that are tracked for every single business case. I worked with a globally distributed team to understand the ROI and bring tangible benefits to the employee experience which would in turn bring savings and value to the business. Our task based approach resulted in an estimated $11.2 Million in cost savings from an improved UX, mobile enablement through responsive design, enhanced search, increased self service, streamlined approvals, and from simplifying the platform's content and facilitating personalization.</p>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-verizon-4.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-3"> 
              <h3 className="mt-5">My Projects</h3>
              <p>My responsibilities on the following projects included, but were not limited to:</p>
              <Row className="m-0">
                  <Col lg={6}>
                    <ul className="checklist mb-0 mb-lg-3">
                      <li>Design & Prototyping</li>
                      <li>Information Architecture</li>
                      <li>UI/UX Requirements</li>
                      <li>UX Research & Testing</li>
                    </ul>
                  </Col>
                  <Col lg={6}>
                    <ul className="checklist">
                      <li>Design & Coding Reviews</li>
                      <li>Copy Writing & Editing</li>
                      <li>Marketing Promotions</li>
                    </ul>
                  </Col>
                </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>OneProfile</h4>
              <p>Roles-based access to employee profiles, allowing them to connect with each other, letting individuals access and, in some cases, edit their employee data and submit requests, and enabling leaders to manage their teams.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Minimizing the real estate taken up by elements while allowing ample room for the varying dynamic content within those elements. Creating flows that are intuitive for a global audience.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-one-profile.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Wayfinding</h4>
              <p>Phone and iPad Mobile applications empowering users to locate people and places on campus with step-by-step directions, find open seating, do workspace check-ins, book conference rooms, and discover nearby points of interest.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Providing access to menus and help information while still showing the main information given a limited screen size.</Alert>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-wayfinding.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>About You</h4>
              <p>Supporting employees with personalized access to benefits, amenities, HR and company information, learning and career development resources, community and company events, self-service tools for themselves and their teams, and more.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Accounting for roles-based adjustments in the layout and content and providing personalization features to give users quick access to the items they use most.</Alert>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-about-you.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          {/* <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>The Feed</h4>
              <p>Driving engagement and culture through employee communications, by providing news and videos about topics relevant to employees, that enable personalization and social interaction features such as saving, commenting, rating, and sharing.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Establishing guidelines for article headings and descriptive blurbs to allow for placement on card grids and designing the layout to accommodate that dynamic text.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-vzweb.png" alt="" fluid rounded className="border" />
            </Col>
          </Row> */}
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Digital Assistant</h4>
              <p>Enabling employee self-service with AI chatbots that search multiple internal platforms, find results quickly, and take action on those findings, from simple tasks like making a call to more intricate ones like submitting a job opening.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Creating interactive elements for a mobile-sized chatbot to allow a readable display of information and enable the easy selection of provided options in a conversation style output.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-digital-asst.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Developer Portal</h4>
              <p>Allowing developers to find and contribute to a central hub of software engineering resources, including a comprehensive, searchable, and filterable collection of Verizon's APIs, their documentation, and tools for connecting to them.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Determining the information architecture needed to support various layouts of filterable information in a way that provides the data most important to developers searching through an API library, and creating the layouts and designs to display that information. Creating ituitive controls for filtering the results with form fields or by tag selection.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-devops.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Emergency Notification System</h4>
              <p>A customizable template for rapidly deploying emergency notifications, indicating the threat level, providing a call-to-action, and displaying appropriate visuals for the situation which could be selected from a library of email header images.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Creating a reusable template for communications clearly shows the threat level, type of emergency, and call to action. Providing a library of specific images that can be used for the most common types of emergencies and generic images that can be used for any purpose. Making sure the imagery isn't political or overwhelmingly alarming, while displaying the appropriate level of urgency.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-emergency.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Virtual Assistance Center</h4>
              <p>Allowing employees to 'skip the line' by enabling them to access systems information and self-service tools for their hardware and software needs rather than going through IT services. Field tested by actual users seeking IT assistance.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Designing a desktop application and accounting for its limitations and differences from a browser based application. Encouraging interaction from a test audience of 'volunteers' who were not particularly enthusiastic about assisting.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-vac.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Onboarding</h4>
              <p>Communications and visuals providing new employees with information about their position and the opportunities available during the employee journey, including quick links to helpful tools and resources for each stage of employment.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Managing conflicting instruction from different stakeholders and finding compromises in the design and content to appease everyone.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-infographic.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Learning Resources Search</h4>
              <p>Using AI machine learning and NLP to improve search accuracy and efficiency for the Learning Management System, including the implementation of contextual smartboxes and filters for search and personalized layouts for search results.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Working with AI to provide the best possible search experience for users. Finding distinct, recognizable iconography to represent copious amounts of various types of data. Creating intuitive controls for varous types of filters.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-vzsearch.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className="pb-5 pb-lg-0 pe-0 pe-lg-5">
              <h4>Learning Management System</h4>
              <p>Providing employees an extensive selection of learning resources and the tools to find, filter, gather information, get approvals, enroll, make payments, track progress, rate, and virtually attend classes, courses, and certifications.</p>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> Creating consistent templates that work with dynamic content for different types of learning experiences that have some common elements and some unique ones.</Alert>
            </Col>
            <Col lg={5} className="pb-5 pb-lg-0 mb-0 mb-lg-3">
              <Image src="img/casestudies/cs-verizon-vzlearn.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>

      </div>

      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">
            <span><i className="bi bi-quote lquo"></i>Tonya was instrumental in defining the look and feel of our new product line, TeamSuccess. &hellip; Her requirements documentation is the most detailed and accurate that I have seen in my 25+ years <span className="text-nowrap">in Product.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Barry Tallis</div>
              <small>Director of Product</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="success" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}>              

              <h3>TeamSuccess</h3>
              <p className="lead">Extended the company's portfolio from a single product to a suite of products • Achieved feature parity with competitors, becoming a leader in the market • Created a design system and component library to enable consistent design and rapid development</p>

              <h4>The Pitch</h4>
              <p>Create a one-of-a-kind customer success tool that allows Customer Success Managers to grow their customer relationships and their revenue. These users should be able to easily monitor, automate and streamline their customer lifecycle. The app should allow them to create more upsell opportunities, and reduce the risk of churn with proactive alerts.</p>

              {/* <p className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="577">More screenshots & stats &raquo;</p> */}

              </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-tsx-rsp.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>My Role and Responsibilities</h4>
              <p>TeamSuccess was a brand new product started at TeamSupport, so I worked on it through the entire software development lifecycle &mdash; research, planning, design, testing, implementation, quality assurance, marketing, launch, and maintenance. I was the lead (ie. only) UI/UX Engineer on the project.</p>

              <p>The UI/UX decisions I made in building this app had two main drivers. First, we wanted to build a first class customer success tool that could compete in the burgeoning market. Second, we wanted the application to work hand-in-hand as a part of a suite of products with our existing customer support application and with a new customer insights application that was concurrently in production. We needed to build the TeamSuccess application from the ground up geared towards the user experience for Customer Success Managers, while creating a design system and component library that could be leveraged in the other applications which were geared toward Customer Support Reps and Business Analysts.</p>

            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
            <Image src="img/casestudies/cs-tsx-profile.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              
              <Alert variant="info" className="mb-4">
                <p><strong>Challenges Overcome:</strong> Building a consistent design system for three products that were using different technologies was formidable, but I was able to find a decent compromise. We were slowly working on modernizing the support application, moving it to React, which is what we used for the success application. The look and feel of the existing app was outdated, while we wanted a clean, contemporary look and feel for the suite of products.</p>
                <p className="mb-0">With the existing resources it was not possible to do a full refresh on the existing app, so I needed to create an interim design that we could use on the specific areas of the older app that would bridge the gap between the old and new design. This would allow the restructured areas of the support application to look similar enough to the other two apps in the suite, which fully used the new design, to be recognizable as a sister product. Meanwhile, the modernized pages retained enough similarity to the areas of the app that had not yet gotten a refresh to keep it from looking like a half-completed frankensteined product. I built it in such a way that once the entire application was transitioned to React, we could essentially 'flip a switch' to bring the design across the threshhold to fully embrace the new look and feel.</p></Alert>

              </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/slides/ns-team.jpg" alt="" fluid rounded className="border mb-3" />
              <Image src="img/casestudies/cs-tsx-design-sys.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>Research and Planning</h4>
              <p>The first steps in this process involved research and planning. We performed customer interviews, developed user journeys, user stories, data models, the information architecture and workflow, and researched design patterns, features, and even terminology used for the success industry, while also taking into consideration design patterns and features used in other SaaS industries. This helped to drive each of the decisions around the UI/UX, information architecture, features, and content of the application.</p>

              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> One challenge that presented itself when working on the information architecture and content was that the terminology used for the success industry differed somewhat from what was used in the support industry when talking about the same thing. The TeamSuccess app was meant to be both a standalone product that could also integrate with our TeamSuccess product to work hand-in-hand with it. We didn't want to confuse users from the customer success industry by using different terminology than they were used to, but we also didn't want our existing customer support users to get confused when using both products. In the end we decided to go with the support terminology for the majority of the content. We used iconography to help visually tie together wording in the navigation that meant the same thing but was phrased differently in each app, and in our knowledge base articles we used wording such as "abc, also known as xyz" to help bridge the gap. Given that the users most likely to use both applications were customer success managers, this decision was the logical choice.</Alert>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-tsx-user-journey.png" alt="" fluid rounded className="border mb-3" />
              <Image src="img/casestudies/cs-tsx-ia.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <p>Other factors taken into consideration were the business needs behind the project, the maker experience (MX), and the shared needs of the suite of products. For example, for business needs, we followed an agile process to get the product to market as quickly as possible while gaining feature parity with our competitors. For MX, when evaluating different ways to implement a feature, we'd take into consideration the development complexity and the ease of which the code behind the feature could be maintained and extended over time.</p>
              <p>Because this was a new application, we decided to use a more modern technology framework than what was in use in the existing product, which would allow us to build the application more quickly and with more flexibility. I performed extensive research in choosing the right framework and components to use. And for the shared needs, when building the design system and component library we needed to make sure that each element would meet the needs for all applications, not just the success application that we were starting with.</p>

              </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-tsx-js-frameworks.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              
              <Alert variant="info" className="mb-4">
                <p><strong>Challenges Overcome:</strong> The differences in the technology used for each app also affected the decisions I made on which components to include in the component library. Due to the limited timeframe we had to build the TeamSuccess app, the developers wanted to grab the first item out there they found that would meet their immediate needs. However, I insisted that some initial research be done on each component and would only allow it to be included in the library and used in the app if it met certain criteria.</p>
                <p>It had to have good UX, as compared to a 'must have' list of properties I created for each type of component. If it could not directly leverage the design system I created, and nothing similar could be found that was able to do that, then its styling had to be customizable so that its look and feel would remain consistent with the global theme I created. If the component was something that one of the other apps currently used or would later need, then its functionality had to include all of the features needed for those apps as well as the success app. The rule was 'there can be only one'.</p>
                <p>We had a chance with the success app, in building it from scratch, to not fall into the same pitfalls as the support app did during its 10 years of development before I came on board. For that app, the developers had essentially used any component they wanted, without any comparative research and without checking to see what was already in use in the app. This led to there being multiple third party assets in use of the same type, and multiple versions of the same assets in use in different areas of the app. This adversely affected performance, as well as the ability to maintain and modernize the product.</p>
                <p className="mb-0">While there was some initial pushback from the development team, they acknowledged the need for these rules and soon discovered that it didn't cost that much additional time to do the right thing.</p>
                </Alert>

              </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/casestudies/cs-tsx-table-component.png" alt="" fluid rounded className="border mb-3"/>
              <Image src="img/casestudies/cs-tsx-component-library.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>Design and Development</h4>
              <p>During this process I created mockups, prototypes, and UI/UX requirements for each feature. I helped to guide the technical design, developed frontend code and worked closely with other developers guiding them on the implementation of components and how to use the design system that I developed, and participated in code reviews and quality assurance. In addition to being the UI/UX lead on the project, I often assisted project management in guiding and tracking the progress of the product's development.</p>

            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-tsx-mockup.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              
              <Alert variant="info" className="mb-4">
                <p><strong>Challenges Overcome:</strong> Wanting to get to market as soon as possible meant that this project had a limited timeframe. It also had limited resources. While we had eight developers on the project, we only had one designer (me). At the same time I was working on the existing product and white labelling a third product we were adding to the suite. In the beginning I had enough lead time to work on the overall design and the frontend code to implement it, while the developers were working on the back end. When we started building out specific pages and features, I would have a single sprint to work out the design details before the development sprint. However, about two-thirds of the way in the developers hit their stride and their productivity increased. I was no longer able to produce mockups or UI requirements before they started building new pages, and instead was playing catch-up fixing all of the UI/UX errors after they were done.</p>
                <p className="mb-0">That was not ideal and definitely not sustainable. Fortunately, I noticed that the developers were essentially making the same mistakes repeatedly. Using this information I put together a UI/UX checklist for them to follow, so that they could verify that they were following a consistent design pattern from page to page and had the instructions for how to properly accomplish the areas that they had trouble with. We had a brief training session to go through the checklist and to hit the highpoints of leveraging the design system, component library, and existing layouts and elements that were already approved. From that point on, the developers were able to successfully churn out more pages using the resources at hand. At the beginning of each sprint I would speak with each developer and offer some initial guidance on their assigned tasks, and during the sprint demo I would take notes on areas needing improvement and discuss those with the developers afterwards. This allowed us to keep the project on track and freed up some of my time to work on other endeavors.</p></Alert>

              </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/casestudies/cs-tsx-sprint.png" alt="" fluid rounded className="border mb-3" />
              <Image src="img/casestudies/cs-tsx-review.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>Testing and Launch</h4>
              <p>Before going to market, the app went through extensive quality assurance testing and we began to use it internally to evaluate its real world usage. We also worked with a select group of interested customers to launch an early access program that allowed us to elicit user feedback and make final adjustments before the official launch, making sure that the tools and features were easy to use and understand and provided the desired results of our customers.</p>              

              <h4>End Results</h4>
              <p>Building the TeamSuccess application was success! We were able to get it to market, integrate it with our other products, and build it in such a way that enables us to rapidly develop new or extended features, leveraging the design system and component library. We've also been able to use those same tools to improve the other products in the suite, driving the whole towards a more consistent, easy-to-use user experience.</p>


            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-tsx-ad.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">
            <span><i className="bi bi-quote lquo"></i>Tonya's ability to multitask while ensuring all details are covered is unparalleled. &hellip;Additionally, Tonya is the strongest UX/UI developer I’ve had the pleasure of <span className="text-nowrap">working with.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Gregory Jackson</div>
              <small>Director of Engineering</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="support" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}> 


              <h3>TeamSupport</h3>
              <p className="lead">Improved performance • Cohesive design • UI/UX issues addressed</p>

              <p>The TeamSupport industry-leading customer support software empowers SaaS companies to reduce churn and increase NPS scores by promptly resolving tickets and easily collaborating with multiple departments to provide a level of support that creates customers for life.</p>

              {/* <p>More screenshots & stats:
                <span className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="568">Application Maintenance</span>,
                <span className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="570">Login Promos Modernization</span>,
                <span className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="572">Advanced Search Modernization</span>,
                <span className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="573">Knowledge Base Modernization</span>,
                <span className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="569">Other Modernization Projects</span>
              </p> */}

              <p>I worked on every aspect of the TeamSupport application, including doing project management, design, development, and quality assurance. Projects were divided into two categories: Product Support, which included bug fixes, technical debt, and maintenance, and Research & Development, which included modernization and new or expanded features. As the Senior UI/UX Engineer I created the overall roadmap for the company's UI/UX direction, built the design system and component library for use in the applications, created guidelines and documentation and led training sessions on UI/UX and front-end development topics.</p>
              
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-tsp-rsp.png" alt="" fluid />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>UI/UX Direction</h4>

              <p>The UI/UX Roadmap for TeamSupport that I developed involves taking all products through a Design Thinking inspired workflow, which should align with the SDLC: Research &gt; Strategize &gt; Implement &gt; Repeat. This involved defining roles and responsibilities, setting goals and priorities, and developing a workflow for UI/UX deliverables. It outlined the various avenues of research open to us and when to use each method, the steps involved in using those research findings to define the problems and ideate and prioritize solutions, and the short-term and long-term deliverables expected during the implementation process.</p>

              <p>The roadmap was supplemented by extensive documentation on our global app branding and shared components, UI/UX guidelines for components and content, and articles outlining the results from research on topics such as frameworks and architectures, design and UI elements, components and component libraries, and more.</p>

              <p>In addition to providing guidelines for the development team to follow, I also led training courses and provided 1:1 guidance for the development team on these topics.</p>
              
            </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/casestudies/cs-tsp-roadmap.png" alt="" fluid className="mb-3 mx-auto" style={{maxWidth: '225px'}} />
              <Image src="img/casestudies/cs-tsp-ui-docs.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>Product Support</h4>

              <p>Under the Product Support umbrella, I worked to maintain and incrementally improve the TeamSupport application over time, using the research and guidelines I completed. This included providing bug fixes, UI/UX improvements, and performance enhancements.</p>

              <p>Some of the performance boost initiatives I led included upgrading our third party assets, optimizing and minifying assets, improved caching and the usage of CDNs, updating the app to use a system font stack and a singular icon source, and other tasks aimed at consolidating, streamlining, and modernizing the application's code and assets, while improving the customer experience.</p>

              <p>In working on bugs and technical debt for the app, I worked closely with the Support and Product teams to analyze our customers' needs and prioritize the development tasks. This included doing extensive reviews of the customer feedback and performing a thorough evaluation of the application's functionality and usability.</p>

              <p>Additionally, I worked in a project management capacity, providing requirements and direction to other developers as they worked to improve the product.</p>

              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> The product had existed for ten years prior to my joining the team, and during that time the company never had a designer or UX professional. The developers simply got by using out-of-the-box UI frameworks. Surprisingly, they didn't do too bad of a job with it, but there was a significant amount of UI cleanup and UX improvements that needed to be made, as well as accessibility issues to resolve and a number of front-end assets and code that needed to be centralized and optimized. I was like a child at the playground. I quite enjoyed the challenge of bringing the UI/UX of the app up-to-date.</Alert>
              
            </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/casestudies/cs-tsp-rte.png" alt="" fluid rounded className="my-3" />
              <Image src="img/casestudies/cs-tsp-mods.png" alt="" fluid rounded className="border mb-3" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>

              <h4>Research & Development</h4>

              <p>Under the Research & Development umbrella, I worked with the development team to update the legacy code, which used a variety of older technologies. We modernized the code to use React and leveraged the global design system and component library that I developed. I worked in a project management and design capacity on these updates, providing UI/UX and functionality requirements, mockups, prototypes, and graphic assets, guiding the technical design, and participating in code reviews and quality assurance.</p>
              <p>The goal was not only to modernize the design and improve the user experience, but also to restructure and centralize the code to take advantage of modern coding techniques to improve performance and make the code easier to maintain and grow over time.</p>
              <p>When working on the updated designs for these features, I consulted with stakeholders, reviewed customer feedback, bug tickets, and feature requests, performed a competitive analysis, and leveraged modern design patterns for SaaS applications.</p>
              <p>In addition to this research and collaboration, I also researched each technology, framework, and component used in modernizing these features to make sure that it met our stringent criteria.</p>

              <Alert variant="info" className="mb-4"><p><strong>Challenges Overcome:</strong> Amongst many other challenges, we had some difficulty knowing where to start, given the breadth of work needed. We used a priority matrix to sort the tasks by the ease of implementation vs the task importance, which made it clear where we could make the biggest impact.</p><p>Making major decisions about the UI framework to use as the foundation of the design system and which Javascript framework to use to modernize the app and build our component library, were also challenging. I did not want to make the wrong choice and send us down a path that would be difficult to alter later. I started with a list of key criteria to evaluate the frameworks to see how closely they met our specific needs &ndash; both immediate and in the future &ndash; and to gauge their overall performance and stablilty. Then I researched the top tools available and rated each against our criteria. I then pulled in the development team to get their input, and with some discussion, they agreed with my final recommendations.</p></Alert>

            </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/casestudies/cs-tsp-gcf.png" alt="" fluid rounded className="border mb-3" />
              <Image src="img/casestudies/cs-tsp-gcf-02.png" alt="" fluid rounded className="border mb-3" />
              <Image src="img/casestudies/cs-ui-frameworks.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">
            <span><i className="bi bi-quote lquo"></i>There are few people I have come across in my career that put the level of effort and passion into work that <span className="text-nowrap">Tonya does.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Travis Pitts</div>
              <small>Senior Software Engineer</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="tickets" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}> 

              <h3>TeamSupport Ticketing System</h3>
              
              <p className="lead">Expected Results: Increased sales • Reduced churn • Improved User Experience</p>

              <p>The number one feature of the TeamSupport application, and the most complicated, is Ticketing &ndash; the ability for application users and their external customers to create and update support tickets.</p>

              {/* <p className="islink" data-toggle="modal" data-target="#modal-project-info" data-project-id="575">More screenshots & stats &raquo;</p> */}

              <p>Under Research & Development for TeamSupport, the largest project I worked on was leading the initiative to redesign and modernize the ticket page.</p>

              <p>Before starting the redesign, I did extensive research on the existing ticketing features to understand all aspects of the current functionality. I reviewed reams of customer feedback and years of bug reports to learn how our customers were using it and how they evaluated the user experience, what their pain points were with the product, what they liked about it, and what feature requests they had. I also received additional feedback from our Customer Support and Customer Success teams who both use the product themselves and work closely with the customers every day, and so are well versed in the user's expectations.</p>

              <Alert variant="info" className="mb-4"><p><strong>Challenges Overcome:</strong> The founders of the company were the original designers of the ticket page, so had some very personal feelings about how things should be done on it. They also had contact with a few outspoken customers who had expressed their own opinions about how the ticket page should work. Occasionally these beliefs conflicted with the feedback received from the majority of the users.</p><p className="mb-0">Some of these battles were won by reviewing the related feedback, making the executives aware of pain points the customers were having that they hadn't realized. For others we did additional research and testing, such as A-B testing to compare two potential solutions to see which performs better with users.</p></Alert>

              <p>During the design process I worked closely with all internal and external stakeholders to make sure that the suggested adjustments to the design and functionality met the needs of all affected parties. Key customers were consulted during the design phase and given a sneak peak of the modernized ticket page, so that we could get their feedback on the usability of new or revamped features. The design was driven not just from the user experience, but also from business needs and technological needs and limitations, with the goal to find the best balance in the finished product.</p>

              <Alert variant="info" className="mb-4"><p><strong>Challenges Overcome:</strong> Finding the right balance meant making many compromises. It was necessary to rank proposed features as "must have", "nice to have", and "future consideration". By doing this, no good ideas were thrown out just because they couldn't be accommodated at this time.</p><p className="mb-0">The plan was to target the must haves, and take the nice to haves on a case-by-case basis to see which we could easily squeeze in without compromising the timeline. The future consideration items were archived so that when the time, resources, or technology became available to make that happen, we'd already have mockups and UI/UX requirements in place to get us off to a good start.</p></Alert>

              <p>I worked in a project management and design capacity on these updates, providing UI/UX and functionality requirements for around 70 properties on the ticket page, defining their individual functionality and how they interacted with other elements in the application, creating mockups and prototypes, guiding the technical design, and developing frontend code. This was my most extensive project and it went through several iterations.</p>

              <Alert variant="warning" className="mb-4"><strong>Challenges Not Overcome (Yet):</strong> Unfortunately, before the full development phase started, this project got shelved as the product roadmap was re-prioritized under new leadership. The plan is still to move forward with the proposed design, it is just a matter of having development resources available to assign to the project. When the new ticket page is implemented, it is expected to be a major driver in new sales and reduced churn.</Alert>

            </Col>
            <Col lg={5} className="text-center pb-5 pb-lg-0 d-flex flex-column justify-content-around">
              <Image src="img/screenshots/ticket-page.png" alt="" fluid rounded className="border mb-3" />
              <Image src="images/slides/ss-990x640/team-team-575-38.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">            
            <span><i className="bi bi-quote lquo"></i>Tonya efficiently managed our team of developers, ensuring seamless collaboration and driving successful releases. Her strategic approach and commitment to quality played a pivotal role in the continuous improvement of <span className="text-nowrap">our products.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Eric Harrington</div>
              <small>Co-Founder & COO</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="engineering" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}> 
              <h3>Engineering Management</h3>
              <p className="lead">Increased Productivity • Elevated Work Quality</p>
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>The Road to Management</h4>
              <p>At TeamSupport I began as a Senior UI/UX Engineer and later got promoted to Team Lead. Next I took on the Release Manager role in addition to my existing responsibilities. The company was going through a period of change and we lost several members of Engineering, including the Engineering Manager, Director of Engineering, Chief Technology Officer, and several Software Engineers. While waiting for the positions to be backfilled, I took on the role of interim manager. Some of those duties I was asked to take on temporarily. Others I picked up simply because no one was doing it, it needed to be done, and there was no one there to tell me not to. In recognition of my work, I officially received the promotion to Engineering Manager after 8 months, while continuing as Release Manager and spending a reduced portion of my time on UX Engineering.</p>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-em-qq-00.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>Improving the Quality and Quantity of Items Released</h4>
              <p>My management style differed somewhat from the previous leadership. As a servant leader, I worked with the Engineering and Product teams to make several changes in our procedures to better meet the needs of the teams.</p>

              <Alert variant="info" className="mb-4">
                <p><strong>Challenges Overcome:</strong> I am a strong believer that you hire people because of their expertise, so you should take full advantage of that.</p><p>With Product and Engineering working together we were able to put into place several measures that were highly recommended by the teams, and yet had not previously been taken into serious consideration by leadership.</p><p className="mb-0">We were able to get important issues added to the roadmap that were long overdue to be addressed. We moved to a more agile workflow and instituted new policies and standards that allowed us to 'do the right thing', benefitting both the employees and the product.</p></Alert>

            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-4">
              <Image src="img/casestudies/cs-em-qq-01.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>Results</h4>
              <p>These changes produced measurable results &ndash; reduced turnover, improved morale, increased productivity, and elevated work quality.</p>
              <Alert variant="info" className="mb-4">
                <p><strong>Challenges Overcome:</strong> It had been a long time since we had been able to complete our sprint goals on time, so finally getting on track was a huge success.</p><p>Our enhanced testing allowed us to improve the overall quality of our work, something that did not go unnoticed by the customers.</p><p className="mb-0">Those factors combined meant that we were closing out bugs faster than ever, there were fewer bug reports coming in, and the reports that came in had a much lower urgency level than before, meaning they were only minor fixes.</p></Alert>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-em-qq-03.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}> 
              <h4>But What About UX?</h4>
              <p>As mentioned, there was a reduction in the amount of time I spent performing UX duties, but that did not impact the user experience. Why? Because I had worked myself out of a job.</p><p>During my time as a Senior UI/UX Engineer, in addition to performing user focused research, design, and development, I also worked to cross-train my team on these methods. I led workshops, produced instructional documentation and extensive UI guidelines, and worked individually with developers teaching them best practices and how to work with the specific styles and components needed for the apps.</p><p>I also spearheaded the creation of a comprehensive Design System and Component Library. This provided a robust front end framework allowing developers to build consistent, easy to use, accessible, responsive functionality in minutes that adhered to the global branding guidelines. The team quickly became adept at design and front end development.</p><p>With these improved skills, and with my having completed a backlog of mockups, prototypes, and UI/UX requirements for upcoming projects, that left little for me to do other than review and advise.</p>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/screenshots/tips-and-tricks.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>

      </div>

      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">
            <span><i className="bi bi-quote lquo"></i>Tonya's willingness to lead the company Events Team highlighted her creativity and dedication to fostering a positive and collaborative environment. Her efforts in organizing activities significantly strengthened team relationships and morale, underlining her commitment to nurturing a supportive <span className="text-nowrap">workplace culture.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Michael Unquera</div>
              <small>Director of Cyber Security and Privacy</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="engagement" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}> 
              <h3>Employee Engagement</h3>
              <p className="lead">Decreased Turnover • Improved Team Dynamics</p>
              <p>About fifteen years ago I joined the newly created employee engagement committee at my workplace. At the time, I didn't have much involvement with my coworkers outside of immediate business needs. In fact, I was growing a rather intimidating reputation, since part of my job was to identify UX issues, get them fixed, and instruct my coworkers on the proper methods to use. As such, whenever I was seen leaving my desk to go talk to someone, I would hear people say "Uh-oh, who broke something? Here comes Tonya!" I needed people to see me in a different light. But, what I thought was going to just be some simple party planning turned into a life changing experience.</p>
              <p>Through participating in the company and community events the team and I hosted, the employees all got to know each other better. People started to see me for me. I still had to provide constructive criticism, but once people knew me on a personal level, they were no longer ruffled by it. I had always been nice about it, but a person's impression of someone tends to color how the things they say are interpreted. My coworkers and I formed valuable friendships, and many of us still keep in touch.</p>

            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-ee-rbf.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>              
              <p>It wasn't just my personal experience that changed, however. There was an overall shift in company morale. People were happier at work, and fewer people left for other opportunities. Not only that, but since employees were communicating more with people outside of their immediate teams, they incidentally would find out valuable information they would have otherwise missed. For example, two teams were working on the same problem without knowing it, so they were then able to collaborate on a solution, saving time and money. Designers got to hear more about the customer experience from speaking with business analysts, which led to more user-friendly design. The 'simple party planning' affected just about every aspect of the business, including team dynamics, employee turnover, customer experience, efficiency, and profitability.</p>
              <p>Eventually I did move on in my career, and since there wasn't an employee engagement committee at my new workplace, I founded one. This gave me the opportunity to see whether the previous results I'd seen were just a one-off. I was greatly pleased to find out that it was not an isolated instance. The employee engagement committee produced the same results!</p>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-3">
              <Image src="img/casestudies/cs-ee-balloon.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <Alert variant="info" className="mb-4"><strong>Challenges Overcome:</strong> When the pandemic hit and the company transitioned to work from home, we had to modify the events and activities to work in a distributed environment. We were successful, however, and having this form of interaction was a huge help in combating the feelings of isolation. As the company grew and branched out across the globe, we overcame other issues, like how to coordinate virtual events for a larger audience, and how to handle cultural and time zone differences in the preferred activities and times for employee engagement events.</Alert>
              <p>All-in-all, I believe that fostering employee engagement is a worthy endeavor with an immeasurable return on investment, whether in-office or in a remote environment.</p>
              {/* <p>[ simpsons image, maybe link to virtual activities, include pics from some events ]</p> */}
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-ee-pandemic.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>
      </div>



      <div className="kudo-strip">
        <Container fluid="xl">
          <p className="lead mb-0">
            <span><i className="bi bi-quote lquo"></i> If Tonya didn't have any homework growing up, she'd make some up <span className="text-nowrap">for herself.<i className="bi bi-quote mirror-icon rquo"></i></span></span>
          </p>
          <div className="d-flex">
            <div className="pe-2">&mdash;</div>
            <div>
              <div>Sue Abna</div>
              <small>Tonya's Mom</small>
            </div>
          </div>
        </Container>
      </div>

      <div id="origin" className="cs">
        
        <Container fluid="xl">
          <Row>
            <Col lg={7}> 
              <h3>An Origin Story</h3>
              <p className="lead">Crafty Marketing • Targeted Revenue</p>
              <p>I have been professionally working in the UI/UX field for over 25 years, but it's actually much longer if you start counting from my first paid 'job' as an artist.</p>
              <p>In elementary school, I was a master with my crayons, so I decided to monetize my skills. I developed a business plan where I would do drawings for the other six year olds to take home to present as their own artwork. Each drawing was specfically targeted to pull at the heartstrings of their mothers, which they could then exploit for favors and rewards. I would sell these drawings after lunch for a nickel each. Lunch at the time cost 95 cents, and most kids would bring a dollar to pay for it and then have a nickel left over burning a hole in their pocket. And so my illustrious career began. I was leveraging my skillset to take advantage of supply and demand while employing persuasive marketing strategies and calculating the market price my chosen demographics would pay, long before I knew what any of those words meant.</p>
            </Col>
            <Col lg={5} className="text-center align-self-center pb-5 pb-lg-0">
              <Image src="img/casestudies/cs-origin.png" alt="" fluid rounded className="border" />
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
};

export default CaseStudies;
