import React, { useRef } from 'react';
import { ParallaxProvider } from "react-scroll-parallax";
import { PortfolioBanner } from "../components/PortfolioBanner.tsx";
import PortfolioBlurb from '../components/PortfolioBlurb.jsx';
import { Container } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { CardBody } from 'react-bootstrap';
import { CardHeader } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import { Slide } from "react-awesome-reveal";
import ContactIcons from '../components/ContactIcons';
import PhotoAlbum from '../components/PhotoAlbum';
import "../styles/portfolio.scss";
import "../styles/hero-portfolio.scss";

const Portfolio = () => {
  const appetizers = useRef(null);
  const maincourse = useRef(null);

  return (
    <>
      <div id="portfolio">&nbsp;</div>
      <div className='mt-5'>
        <ParallaxProvider>
          <PortfolioBanner />
        </ParallaxProvider>
      </div>
      <div className="portfolio-stack">
        <Container fluid="lg">
          <PortfolioBlurb/>
        </Container>
      </div>


      <div id="intro">
        <Container fluid="lg">
          <Row>
            <Col md={8} lg={9} className="pe-0 pe-md-5">

              <h1 className="mb-4">My Portfolio</h1>
              <p>When you've been designing and developing websites and applications for over 25 years, it can be challenging to decide what to put in your portfolio. Some of it you can't show and some of it you don't want to show, but that still leaves quite a bit.</p>
              <p>To break it down, I've provided an <a href="#appetizers">appetizer</a> of slides showing a few snippets of my work from the last two decades. Then, there's your <a href="#maincourse">main course</a>&mdash;full screenshots from a selection of my projects. Finally, if you really want to dive into the nitty gritty, there's the <a href="https://launchgirl.com/portfolio.asp" target="_blank" rel="noreferrer">buffet</a>. My old portfolio was essentially an archive of my work&mdash;screenshots and details from everything I still had access to that I was allowed to show, minus the projects that are so old they're embarassing. I'm providing a link to it, but be warned: it has over 300 projects and 2900 screenshots. Also, I won't be maintaining it, so you may find some links that no longer work.</p>
              <p>This page is essentially just the visuals. If you're looking for details on the process and results, please see my <a href="/CaseStudies">case studies</a>.</p>

            </Col>
            <Col md={4} lg={3} className="ps-0 ps-md-3 align-self-center mb-3">
              <Slide direction="right">
                <Card className="team-content-area text-center mx-auto">
                  <CardBody>
                      <div className="member-thumb my-4">
                          <Image src="img/avatars/avatar2.png" alt="" roundedCircle fluid className="transition-500" />
                      </div>
                      <h3 className="h5">Tonya Abna</h3>
                      <small className="text-uppercase letter-spacing d-block">UX Unicorn</small>
                      <small className="smaller text-uppercase letter-spacing d-block">Designer of Delight</small>
                      <div className="member-social-info my-4">
                        <ContactIcons />
                      </div>
                    </CardBody>
                </Card>
              </Slide>
            </Col>
          </Row>
          </Container>
      </div>

      <div id="subnav">
        <Container fluid="lg" className="text-center">
          <Row>
            <Col md={4} className="flex-fill transition-500" onClick={() => {appetizers.current?.scrollIntoView({ behavior: 'smooth' });}}>
              <h2 className=" r2d h5 text-nowrap">Appetizers <i className="bi bi-arrow-right-circle ms-1 transition-500"></i></h2>
              <div>High-level glimpse at my work.</div>
            </Col>
            <Col md={4} className="flex-fill transition-500" onClick={() => {maincourse.current?.scrollIntoView({ behavior: 'smooth' });}}>
              <h2 className=" r2d h5 text-nowrap">Main Course <i className="bi bi-arrow-right-circle ms-1 transition-500"></i></h2>
              <div>Curated selection of images.</div>
            </Col>
            <Col md={4} className="flex-fill transition-500" onClick={() => {window.open("http://launchgirl.com/portfolio.asp", "_blank", "noreferrer");}}>
              <h2 className=" r2u h5 text-nowrap">Buffet <i className="bi bi-arrow-right-circle ms-1 transition-500"></i></h2>
              <div>Massive archive of projects.</div>
            </Col>
          </Row>
        </Container>
      </div>


      <div id="appetizers" ref={appetizers}>
        <Container fluid="lg">
        <Row className="align-items-stretch">

            <Col lg={3}>
              <h2>Appetizers</h2>
              <h3 className="h5">Small Bytes of my Work</h3>
              <p>I've done a hefty amount of work in the past 25 years or so. These slides give you a quick cross-section of some of my past projects.</p>
            </Col>

            <Col lg={9} className="text-center">

              <Carousel controls={true} indicators={false} interval={8000} pause="hover" wrap={true} touch={true}>
                <Carousel.Item>
                  <Image src="img/slides/ns-launchgirl.jpg" fluid alt="" />
                  <Carousel.Caption><p>Part of my freelance persona, the ever-evolving Launch Girl site.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-ai1.png" fluid alt="" />
                  <Carousel.Caption><p>Venturing into AI, I've learned various ways to create social media videos.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-ai2.png" fluid alt="" />
                  <Carousel.Caption><p>I've also used AI to generate articles, slides, websites, images, <a href="https://launchgirl.com/demos/aishowcase/AI.html" target="_blank" rel="noreferrer">and more</a>.</p></Carousel.Caption>
                </Carousel.Item>                
                <Carousel.Item>
                  <Image src="img/slides/ns-team.jpg" fluid alt="" />
                  <Carousel.Caption><p>In recent years, my work has been as the Senior UI/UX Engineer for a suite of SaaS applications.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-system.jpg" fluid alt="" />
                  <Carousel.Caption><p>For most of my projects, I've done the design from scratch or have built the design system.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-standards.jpg" fluid alt="" />
                  <Carousel.Caption><p>For other projects, I've worked within the confines of existing brand guidelines.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-primeaxs.jpg" fluid alt="" />
                  <Carousel.Caption><p>Here's one of my favorite projects &ndash; a responsive, ajax-driven video service.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-parallax.jpg" fluid alt="" />
                  <Carousel.Caption><p>I've designed and developed a variety of responsive, parallax animated websites.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-buds.jpg" fluid alt="" />
                  <Carousel.Caption><p>Doing personal sites for friends gives me a chance to try new techniques.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-mobile.jpg" fluid alt="" />
                  <Carousel.Caption><p>I've contributed to several smart phone and tablet apps and mobile sites.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-rock.jpg" fluid alt="" />
                  <Carousel.Caption><p>Doing designs and graphics for rock bands helps boost my creativity.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-causes.jpg" fluid alt="" />
                  <Carousel.Caption><p>I also 'do more good', working on sites for the Salvation Army and other causes.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-suite.jpg" fluid alt="" />
                  <Carousel.Caption><p>I love doing a suite of sites that have similar, but distinct designs, like these.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-energy.jpg" fluid alt="" />
                  <Carousel.Caption><p>I've done lots of applications for energy companies.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-energy2.jpg" fluid alt="" />
                  <Carousel.Caption><p>Lots and lots of energy companies.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-travel.jpg" fluid alt="" />
                  <Carousel.Caption><p>And lots of sites for travel companies.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-backoffice.jpg" fluid alt="" />
                  <Carousel.Caption><p>Many of the projects I work on are secure, internal applications.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-backoffice2.jpg" fluid alt="" />
                  <Carousel.Caption><p>Practically every client I've worked on had at least one of these applications.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-small.jpg" fluid alt="" />
                  <Carousel.Caption><p>I've done lots of sites for small businesses in the past.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-fed.jpg" fluid alt="" />
                  <Carousel.Caption><p>I've worked on a ton of government sites of various types.</p></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="img/slides/ns-misc.jpg" fluid alt="" />
                  <Carousel.Caption><p>And I've worked on a whole mish-mash of other websites and applications.</p></Carousel.Caption>
                </Carousel.Item>
              </Carousel>

              </Col>
            </Row>
          </Container>
      </div>

      <div id="maincourse" ref={maincourse}>
        <Container fluid className="text-center gradient-bg">
          <h2>Main Course</h2><h3>My Portfolio Gallery</h3>
        </Container>
        <Container fluid className="py-3">
          <Row>
            <Col>
              <p className="text-center intro">Click on each screenshot for a larger view and to see which roles I filled for that project.</p>
              <div className="phases">
                <ul>
                  <li><div className="bg-primary"><i class="bi bi-lightbulb"></i><span className="ms-1 ms-lg-2">Plan</span></div></li>
                  <li><div className="bg-success"><i class="bi bi-heart"></i><span className="ms-1 ms-lg-2">Design</span></div></li>
                  <li><div className="bg-info"><i class="bi bi-code-slash"></i><span className="ms-1 ms-lg-2">Develop</span></div></li>
                  {/* <li><div className="disabled"><i class="bi bi-x-circle"></i><span className="ms-1 ms-lg-2">Develop</span></div></li> */}
                  <li><div className="bg-warning"><i class="bi bi-clipboard-check"></i><span className="ms-1 ms-lg-2">Test</span></div></li>
                  <li><div className="bg-orange"><i class="bi bi-rocket-takeoff"></i><span className="ms-1 ms-lg-2">Launch</span></div></li>
                  <li><div className="bg-purple"><i class="bi bi-infinity"></i><span className="ms-1 ms-lg-2">Maintain</span></div></li>
                </ul>
              </div>
            </Col>
          </Row>
          {/* <Row  xs={1} sm={2} md={3} xxl={6} className="mt-3">
            <Col className="pb-3">
              <Card className="h-100">
                <CardHeader className="bg-primary"><i class="bi bi-lightbulb"></i><span className="ms-1 ms-lg-2">Plan</span></CardHeader>
                <Card.Body className="p-2">
                  <Card.Text>
                    <ul className="flush small mb-0">
                      <li>UX Research &amp; Analysis</li>
                      <li>Technical Requirements</li>
                      <li>Project Management</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3">
              <Card className="h-100">
                <CardHeader className="bg-success"><i class="bi bi-heart"></i><span className="ms-1 ms-lg-2">Design</span></CardHeader>
                <Card.Body className="p-2">
                  <Card.Text>
                    <ul className="flush small mb-0">
                      <li>Information Architecture</li>
                      <li>Mockups or Prototypes</li>
                      <li>UI/UX Requirements</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3">
              <Card className="h-100">
                <CardHeader className="bg-info"><i class="bi bi-code-slash"></i><span className="ms-1 ms-lg-2">Develop</span></CardHeader>
                <Card.Body className="p-2">
                  <Card.Text>
                    <ul className="flush small mb-0">
                      <li>Stylesheets</li>
                      <li>Front-End Coding</li>
                      <li>Code Reviews</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3">
              <Card className="h-100">
                <CardHeader className="bg-warning"><i class="bi bi-clipboard-check"></i><span className="ms-1 ms-lg-2">Test</span></CardHeader>
                <Card.Body className="p-2">
                  <Card.Text>
                    <ul className="flush small mb-0">
                      <li>User Testing</li>
                      <li>Quality Assurance</li>
                      <li>Iterating on Results</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3">
              <Card className="h-100">
                <CardHeader className="bg-orange"><i class="bi bi-rocket-takeoff"></i><span className="ms-1 ms-lg-2">Launch</span></CardHeader>
                <Card.Body className="p-2">
                  <Card.Text>
                    <ul className="flush small mb-0">
                      <li>Marketing</li>
                      <li>External Documentation</li>
                      <li>Internal Training</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3">
              <Card className="h-100">
                <CardHeader className="bg-purple"><i class="bi bi-infinity"></i><span className="ms-1 ms-lg-2">Maintain</span></CardHeader>
                <Card.Body className="p-2">
                  <Card.Text>
                    <ul className="flush small mb-0">
                      <li>General Maintenance</li>
                      <li>Phased Upgrades</li>
                      <li>Internal Documentation</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Container>
        <Container fluid className="py-3 slides-bg">
          <Slide direction="right">
            <PhotoAlbum />
          </Slide>
        </Container>
      </div>


    </>
  );
};

export default Portfolio;
